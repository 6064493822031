const SYSTEM_USER_NAME = "user_name";
const ACCESS_TOKEN = "access_token";
const REFRESH_TOKEN = "refresh_token";

export const getUserName = () => localStorage.getItem(SYSTEM_USER_NAME);
export const getAccessToken = () => localStorage.getItem(ACCESS_TOKEN);
export const getRefreshToken = () => localStorage.getItem(REFRESH_TOKEN);

export const setUserName = (value: string) =>
  localStorage.setItem(SYSTEM_USER_NAME, value);
export const setAccessToken = (value: string) =>
  localStorage.setItem(ACCESS_TOKEN, value);
export const setRefreshToken = (value: string) =>
  localStorage.setItem(REFRESH_TOKEN, value);
